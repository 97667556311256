import { Layout } from 'antd';
import ImmoAccount from 'assets/images/logo_account.png';
import ImmoApp from 'assets/images/logo_immoapp.png';
import ImmoFonds from 'assets/images/logo_immofonds.png';
import ImmoMove from 'assets/images/logo_immomove.png';
import ImmoSettings from 'assets/images/logo_settings.png';
import ImmoStatistics from 'assets/images/logo_statistics.png';
import themes, { themeConfig } from 'config/theme/theme.config';
import ImmoAppLicenseNotification from 'containers/immoapp/_Tool/LicenseNotification';
import ImmovablePdfNotification from 'containers/immofonds/Immovable/Parts/Notification';
import { ID, USER_ROLE } from 'definitions/constants-fe';
import { getSwitcherIconBorderColor } from 'library/functionHelper';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PAGE, TOOL } from 'route.constants';
import TopbarWrapper from './topbar.style';
import TopbarUser from './topbarUser';

// @ts-ignore
const customizedTheme = themes[themeConfig.theme];

// Constants
const styling = {
  background: customizedTheme.backgroundColor,
  width: '100%',
  height: 64,
};

export default function Topbar(props: any) {
  // Props
  const { switcherIconBorderClass } = props;

  // Reducer
  // @ts-ignore
  const { rx_userAccount } = useSelector((state) => state.GL_Auth);

  // Functions
  const renderLogo = () => {
    const appLogo = getSwitcherIconBorderColor() || ID.settings;
    let toolIcon = null;
    let toolLink = null;
    switch (appLogo) {
      case ID.immoapp:
        toolLink = `/${TOOL.IMMOAPP}/${PAGE.IA_DASHBOARD}`;
        toolIcon = ImmoApp;
        break;
      case ID.immofonds:
        toolLink = `/${TOOL.IMMOFONDS}/${PAGE.IF_HOME}`;
        toolIcon = ImmoFonds;
        break;
      case ID.immomove:
        toolLink = `/${TOOL.IMMOMOVE}/${
          rx_userAccount.role >= USER_ROLE.janitor.value
            ? PAGE.IM_PROCESSES
            : PAGE.IM_START
        }`;
        toolIcon = ImmoMove;
        break;
      case ID.account:
        toolLink = `/${TOOL.ACCOUNT}`;
        toolIcon = ImmoAccount;
        break;
      case ID.statistics:
        toolLink = `/${TOOL.STATISTICS}`;
        toolIcon = ImmoStatistics;
        break;
      case ID.settings:
        toolLink = `/${TOOL.SETTINGS}`;
        toolIcon = ImmoSettings;
        break;
      default:
        toolLink = ``;
        toolIcon = ``;
        break;
    }
    return (
      <Link to={toolLink}>
        <img src={toolIcon} alt="" />
      </Link>
    );
  };

  // Render
  return (
    <TopbarWrapper>
      <ImmovablePdfNotification />
      {/* Note: This returns false until rx_userAccount is set, which is what we want */}
      {rx_userAccount.role >= USER_ROLE.manager.value && (
        <ImmoAppLicenseNotification />
      )}
      <Layout.Header style={styling} className={'isomorphicTopbar collapsed'}>
        <div className="isoLeft">
          <div className="img-section">{renderLogo()}</div>
        </div>

        <ul className="isoRight">
          {/*window.innerWidth >= 768 ? <li><Icon type="appstore" theme="filled" /></li> : ''*/}
          {/*window.innerWidth >= 768 ? <li><Icon type="message" theme="filled" /></li> : ''*/}
          {/* @ts-ignore */}
          <TopbarUser switcherIconBorderClass={switcherIconBorderClass} />
          {/*window.innerWidth >= 768 ? <li><Icon type="setting" theme="filled" /></li> : ''*/}
        </ul>
      </Layout.Header>
    </TopbarWrapper>
  );
}
