import { Button, notification, Space } from 'antd';
import { intl } from 'components/Utility/IntlMessages';
import { ID, SettingsScreen, TAB_IDENTIFIER } from 'definitions/constants-fe';
import {
  setLicenseNotice,
  shouldShowLicenseNotice,
} from 'library/localStorageHelper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import actions from 'redux/immoapp/actions';
import { TOOL } from 'route.constants';

export default (): JSX.Element => {
  // File members
  const mDispatch = useDispatch();
  const history = useHistory();

  // Redux state
  const rx_licenseObjects = useSelector(
    // @ts-ignore
    (state) => state.ImmoApp.rx_licenseObjects,
  );

  // Did mount
  React.useEffect(() => {
    mDispatch(actions.getLicenseObjects(false));
  }, [mDispatch]);

  React.useEffect(() => {
    if (rx_licenseObjects == null) {
      return;
    }
    if (
      process.env.REACT_APP_IA_LICENSE_OBJECTS !== ID.infinity &&
      process.env.REACT_APP_IA_SHOW_LICENSE_NOTIFICATION === String(true) &&
      shouldShowLicenseNotice() &&
      rx_licenseObjects.active_objects >
        Number(process.env.REACT_APP_IA_LICENSE_OBJECTS)
    ) {
      notification.warning({
        key: ID.ia_license_notification,
        placement: 'bottomRight',
        message: intl.formatMessage({
          id: 'license.limitExceededTitle',
        }),
        description: [
          intl.formatMessage(
            {
              id: 'license.limitExceededText1',
            },
            {
              activeObjects: rx_licenseObjects.active_objects,
              allowedObjects: Number(process.env.REACT_APP_IA_LICENSE_OBJECTS),
            },
          ),
        ],
        duration: 0,
        btn: (
          <Space size="small">
            <Button
              size="small"
              onClick={() => {
                setLicenseNotice();
                notification.destroy(ID.ia_license_notification);
                history.push(
                  `/${TOOL.SETTINGS}${TAB_IDENTIFIER}${SettingsScreen.settings_tabs.accounts}`,
                );
              }}
            >
              {intl.formatMessage({
                id: 'global.moreInfo',
              })}
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setLicenseNotice();
                notification.destroy(ID.ia_license_notification);
              }}
            >
              {intl.formatMessage({
                id: 'global.hide',
              })}
            </Button>
          </Space>
        ),
        // This is only executed when clicking the close icon, not when clicking the buttons.
        onClose: () => setLicenseNotice(),
      });
    }
  }, [rx_licenseObjects, history]);

  return <></>;
};
