import { blue, red } from '@ant-design/colors';
import {
  Alert,
  Button,
  Collapse,
  Form,
  InputNumber,
  Select,
  Table,
  Tag,
} from 'antd';
import styled from 'styled-components';
import { palette, size } from 'styled-theme';

const TableWrapper = styled(Table).attrs((props) => ({
  // this gives a nice shadow on the right
  scroll: { x: true, y: props.scrollY },
}))`
  &.limited {
    tbody > tr > td {
      padding: 12px 0px;
    }
  }
  .ant-table-content {
    overflow: auto !important;
    padding-bottom: 1px; // This helps to not show a scroll bar if none is needed.
    table {
      border-collapse: collapse;
      .ant-table-thead {
        & > tr {
          border-top: 1px solid ${palette('custom', 6)};
          border-bottom: 3px solid ${palette('custom', 6)};
          & > th {
            font-size: 13px;
            border-radius: 0 !important;
            font-weight: bold;
            &.noWrapCell {
              white-space: nowrap;
            }
          }
        }
      }
      .ant-table-tbody {
        .rowArchived {
          td {
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 90%;
            &:not(:last-child) {
              opacity: 0.8;
            }
          }
        }
        & > tr {
          & > td {
            &.ellipsis,
            &.ellipsis > * {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            &.noWrapCell {
              white-space: nowrap;
            }
            span.anticon {
              font-size: 18px;
              &.big {
                font-size: 22px;
              }
              &.small {
                font-size: 14px;
              }
              &.xsmall {
                font-size: 13px;
              }
              &.success-color {
                color: ${palette('status', 8)};
              }
              &.error-color {
                color: ${palette('status', 0)};
              }
              &.warning-color {
                color: ${palette('warning', 1)};
              }
              &.neutral-color {
                color: ${palette('status', 2)};
              }
            }
          }
          a[disabled] {
            pointer-events: auto;
          }
        }
      }
    }
  }

  .ant-table-body {
    overflow: auto !important;
    padding-bottom: 1px;
  }

  // Used in ImmoFonds bank table
  .ant-table-footer {
    color: ${palette('text', 3)};
    font-size: 12px;
    padding: 16px 30px;
    font-weight: 600;
    .ant-row {
      justify-content: space-between;
    }
  }
`;

const VerticalTabsSectionWrapper = styled.div`
  .isoBoxWrapper {
    padding: 20px 0;
    @media ${size('max_md')} {
      padding: 20px;
    }
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding-left: 32px;
    padding-right: 32px;
    &.ant-tabs-tab-active {
      background-color: ${palette('primary', 14)};
    }
  }
  .ant-tabs-content-left {
    .ant-tabs-tabpane {
      padding: 8px 40px !important;
    }
  }
  .ant-list {
    li {
      padding-top: 15px;
      padding-bottom: 15px;
      .ant-list-item-meta {
        padding-right: 15px;
      }
      .ant-row {
        margin: auto;
      }
    }
  }
`;

const PageTitle = styled.div`
  margin-bottom: 30px;
  &.margin-small {
    margin-bottom: 15px;
  }
  .hoverable-title {
    cursor: pointer;
    .ant-page-header-back-button,
    .ant-page-header-heading-title {
      color: ${palette('custom', 3)};
      transition: color 0.3s;
    }
    &:hover {
      .ant-page-header-back-button,
      .ant-page-header-heading-title {
        color: ${palette('primary', 15)};
      }
    }
  }
  .ant-page-header {
    padding: 0;
    .ant-page-header-heading {
      .ant-page-header-heading-left {
        flex-wrap: wrap;
        overflow: visible;
      }
      .ant-page-header-heading-title {
        font-weight: 500;
        font-size: 20px;
      }
      .ant-page-header-heading-sub-title {
        overflow: visible;
        flex-basis: 100%;
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  div {
    button {
      @media ${size('max_md')} {
        margin-bottom: 0px !important;
      }
    }
  }
  &.margin-0 {
    margin: 0;
  }
  &.margin-tiny {
    margin-bottom: 4px;
  }
  &.margin-half {
    margin-bottom: 10px;
  }
  &.margin-less {
    margin-bottom: 16px;
  }
`;

const ComponentTitle = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: ${palette('custom', 3)};
  margin: 5px 0;
  &.bigger {
    font-size: 18px;
    margin: 8px 0;
  }
  .documentsSmallText {
    display: block;
    font-size: 12px;
  }
`;

export const BoldTableTitle = styled.div`
  font-size: 17px;
  font-weight: 700;
  color: ${palette('text', 0)};
`;

export const AccentColorGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 8px;
  color: ${palette('custom', 3)};

  div {
    font-size: 12px;
    display: flex;
    align-items: center;

    .square {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      width: 14px;
      height: 14px;
      margin: 0 3px 0 8px;
    }
  }
`;

export const Dot = styled.span`
  width: 8px;
  height: 8px;
  background: ${blue.primary};
  display: inline-block;
  border-radius: 9999px;
`;

export const LanguageDropdownMenu = styled.div`
  display: flex;
  align-items: center;
  color: ${palette('custom', 3)};

  div {
    font-size: 12px;
  }

  button {
    display: inline-flex;
    align-items: center;

    .anticon {
      position: relative;
      top: 1px;
    }
  }
`;

export const TextEllipsisDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ReceiversModalGrid = styled.div`
  & > div.failure-container {
    grid-template-columns: 250px 1fr;
    gap: 32px;
    padding: 16px 0px;
  }
  & > div.user-container {
    grid-template-columns: 136px 1fr 1fr;
    gap: 16px;
    padding: 8px 0px;
  }

  & > div {
    display: grid;
    border-bottom: 1px solid #f0f0f0;
    align-items: center;
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
      border-bottom: none;
    }
    @media ${size('max_sm')} {
      gap: 0px;
      grid-template-columns: minmax(0, 1fr);
      padding: 14px 0px;
    }

    h6,
    p {
      overflow: auto;
    }
  }
`;

const TitleAlert = styled(Alert)`
  margin-bottom: 30px;
  .ant-alert-message {
    font-weight: 500;
  }
  .ant-typography {
    display: inline-block;
    margin: 0;
  }
  &.live {
    border-color: ${palette('status', 14)};
    background-color: ${palette('status', 15)};
  }
`;

const CardTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: ${palette('custom', 3)};
  margin: 5px 0;
  display: flex;
  align-items: center;

  .anticon {
    font-size: 26px;
    margin-right: 12px;
  }
`;

const SmallStatusText = styled.div`
  font-size: 12px;
  &.no-margin {
    margin: 0;
  }
  &.flex-center {
    display: flex;
    align-items: center;
  }
  span.block {
    display: block;
  }
`;

export const ExtraSmallStatusText = styled.div`
  font-size: 11px;
  &.no-margin {
    margin: 0;
  }
  &.small-line-height {
    line-height: 1.25;
  }
  &.no-line-height {
    line-height: 1;

    .ant-btn-icon {
      line-height: 0;
    }
  }
`;

const TinyStatusText = styled.p`
  font-size: 10px;
  &.no-margin {
    margin: 0;
  }
  &.flex-center {
    display: flex;
    align-items: center;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.space};
`;

export const InputNumberFull = styled(InputNumber)`
  width: 100%;
`;

export const ActionSelect = styled(Select)`
  min-width: 200px;
  max-width: 300px;
  margin-right: 8px;

  &.no-margin-right {
    margin-right: 0px;
  }
  @media ${size('max_xs')} {
    width: 100%;
    max-width: 100%;
  }
`;

const ActionBtn = styled(Button)`
  margin-right: 8px;
  &.no-margin-right {
    margin-right: 0px;
  }
  &.margin-bottom {
    margin-bottom: 12px;
  }
  &.filter {
    span.anticon {
      pointer-events: inherit;
      margin-left: 4px;
      &:hover {
        opacity: 0.9;
        transform: scale(1.05);
      }
      svg {
        width: 18px;
        height: 18px;
        padding: 1px;
      }
    }
  }
  i {
    font-size: 20px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &:hover {
    i {
      color: inherit;
    }
  }
  > span {
    font-weight: 500;
    &.less-opacity {
      opacity: 0.5;
    }
  }
`;

// Might be in library
export const FilterIconCustom = styled.div`
  font-size: 15px;
  svg {
    stroke: currentColor;
    stroke-width: 2em;
  }
`;

export const SearchFilterWrapper = styled.div`
  input {
    width: 180px;
    margin: 8px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px 7px 3px;
  }
`;

const SearchBox = styled.div`
  z-index: 3;
  flex-grow: 1;
  position: relative;
`;

export const ContactPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 200px;

  a {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    h5 {
      line-height: 1;
      margin-bottom: 0;
    }
    .ant-typography {
      font-size: 13px;
    }
    .anticon-copy {
      font-size: 12px;
    }
    .anticon-mail,
    .anticon-phone {
      font-size: 18px;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  padding-bottom: 50px;

  .ant-spin-spinning {
    opacity: 1;
    position: static;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  max-width: 1350px;
  &.signInContainer {
    max-width: 900px;
  }
  @media ${size('max_sm')} {
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const PageHeaderExtraWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  button {
    margin-bottom: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media ${size('max_md')} {
    position: relative;
  }
`;

const PageSubTitle = styled.div`
  color: ${palette('custom', 4)};
  font-size: 14px;

  .ant-breadcrumb {
    > span {
      line-height: 2;
      .ant-breadcrumb-link {
        position: relative;
        z-index: 1;
        color: ${palette('custom', 16)};

        a {
          position: relative;
          padding: 4px 8px;
          color: ${palette('text', 5)};
          text-decoration: none;

          &:hover {
            color: ${palette('text', 1)};
            &:after {
              content: ' ';
              height: 100%;
              background: ${palette('custom', 14)};
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              position: absolute;
              opacity: 0.5;
              border-radius: 4px;
              z-index: -1;
            }
          }
        }
      }
      .ant-breadcrumb-separator {
        margin: 0px 3px;
        color: ${palette('custom', 16)};
        vertical-align: 0.1em;

        .anticon {
          font-size: 11px;
        }
      }
    }
  }
`;

const WhiteIcon = styled.div`
  display: inline-block;
  margin-left: 10px;
  i {
    color: ${palette('custom', 0)};
  }
`;

const ButtonWrapper = styled.div`
  @media ${size('max_xs')} {
    width: 100%;
  }
  .button {
    @media ${size('max_md')} {
      width: auto;
    }
    @media ${size('max_xs')} {
      width: 100%;
    }
  }
`;

const ButtonHolders = styled.div`
  @media ${size('max_xs')} {
    width: 100%;
  }
  button:last-child {
    margin: 0;
  }
`;

const DesktopMenu = styled.div`
  text-align: center;
  ul {
    justify-content: center;
    background-color: #194fac;
    border-bottom: none;
    li.ant-menu-item-selected {
      border-bottom: 3px solid #fff !important;
      color: #fff !important;
    }
    li {
      top: 0 !important;
      margin-top: 0 !important;
      line-height: normal;
      border-bottom: 3px solid transparent;
      color: ${palette('grayscale', 14)} !important;
      padding: 20px 20px 15px !important;
      margin: 0 !important;
      @media ${size('max_lg')} {
        padding: 20px 18px 15px;
      }
      a {
        color: inherit !important;
      }
      img {
        margin-bottom: 4px;
      }
      span {
        font-size: 14px;
      }
      &.ant-menu-item-active {
        border-bottom: 3px solid rgba(255, 255, 255, 0.8);
        color: #fff !important;
      }
      &:after {
        border-bottom-width: 0 !important;
      }
    }
  }
`;
const ErrorMsg = styled.div`
  font-size: 12px;
  color: ${palette('custom', 15)};
  border-color: ${palette('custom', 15)};
`;

// see colors in src\settings\themes
const StatusHandler = styled.div`
  display: inline;

  .ant-tag {
    color: ${palette('status', 8)};
    background-color: ${palette('status', 9)};
    border: 1px solid ${palette('status', 8)}55;
    cursor: default;
    font-weight: 500;
    &.single {
      margin-right: 3px;
      font-family: monospace;
      &.small {
        line-height: 16px;
        padding: 0 5px;
        font-size: 11px;
      }
    }
    &.disabled {
      border-style: dashed;
      opacity: 0.5;
    }
    &:after {
      content: none;
    }
  }

  .archived,
  .mandate_inactive {
    color: ${palette('status', 18)};
    background-color: ${palette('status', 19)};
  }

  .archived {
    margin-right: 4px;
    margin-bottom: 2px;
    border: none;
  }

  .error,
  .push_-1,
  .repairs_-1,
  .termination_-1,
  .component_1,
  .component_2,
  .mandate_0,
  .fonds_red {
    color: ${palette('status', 0)};
    background-color: ${palette('status', 1)};
    border: 1px solid ${palette('status', 0)}33;
  }
  .role_-1,
  .role_0,
  .role_1,
  .termination_0,
  .repairs_0,
  .push_0,
  .userVote_0,
  .fonds_unavailable {
    color: ${palette('status', 2)};
    background-color: ${palette('status', 3)};
    border: 1px solid ${palette('status', 2)}33;
  }
  .component_4 {
    color: ${palette('status', 4)};
    background-color: ${palette('status', 5)};
    border: 1px solid ${palette('status', 4)}33;
  }
  .push_2,
  .role_2,
  .repairs_1,
  .termination_1,
  .component_3,
  .mandate_loading,
  .vote_1,
  .vote_online,
  .vote_beforehand,
  .userVote_1,
  .userVote_2 {
    color: ${palette('status', 6)};
    background-color: ${palette('status', 7)};
    border: 1px solid ${palette('status', 6)}33;
  }
  .push_3,
  .component_5,
  .component_6,
  .repairs_2,
  .termination_4,
  .mandate_1,
  .vote_2,
  .vote_end,
  .userVote_4,
  .fonds_green {
    color: ${palette('status', 8)};
    background-color: ${palette('status', 9)};
    border: 1px solid ${palette('status', 8)}33;
  }
  .role_4,
  .termination_2,
  .userVote_3 {
    color: ${palette('status', 10)};
    background-color: ${palette('status', 11)};
    border: 1px solid ${palette('status', 10)}33;
  }
  .push_1,
  .role_5,
  .termination_3,
  .vote_0,
  .vote_planning {
    color: ${palette('status', 12)};
    background-color: ${palette('status', 13)};
    border: 1px solid ${palette('status', 12)}33;
  }
  .vote_live,
  .role_3 {
    color: ${palette('status', 14)};
    background-color: ${palette('status', 15)};
    border: 1px solid ${palette('status', 14)}33;
  }
`;

// Might be in library
export const FormItemSwitch = styled(Form.Item)`
  flex-direction: row !important;
  .ant-form-item-label {
    display: flex;
    align-items: center;
    padding: 0;
    label {
      flex-direction: column;
      line-height: 1.5em;
      span {
        width: 100%;
        @media ${size.max_sm} {
          margin-bottom: 4px;
        }
      }
    }
  }
  .ant-form-item-control {
    text-align: right;
    justify-content: center;
  }
  @media ${size.max_sm} {
    .ant-form-item-label {
      flex: 0 0 80% !important;
      max-width: 80% !important;
    }
    .ant-form-item-control {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  }
`;

const CustomForm = styled.div`
  .ant-select .ant-select-selector,
  .ant-picker .ant-picker-input > input,
  textarea.ant-input,
  input.ant-input {
    font-size: 13px;
  }
  // Bugfix because ANTD is handling this quite bad
  .ck-read-only,
  .ant-input-disabled,
  .ant-picker-disabled,
  .ant-picker-disabled input,
  .ant-select-disabled,
  .ant-select-disabled > .ant-select-selector,
  .ant-select-disabled > .ant-select-selection,
  .ant-select-disabled > .ant-select-selector .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.7) !important;
    background: ${palette('grayscale', 10)} !important;
    cursor: not-allowed !important;
    .ant-select-selection-item {
      cursor: not-allowed !important;
    }
    .ant-select-selection-placeholder {
      opacity: 1;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-select-disabled.ant-select-multiple > .ant-select-selector {
    .ant-select-selection-item {
      border: 1px solid ${palette('grayscale', 1)};
      background: ${palette('grayscale', 8)};
      .ant-select-selection-item-remove {
        cursor: not-allowed !important;
      }
    }
  }
  .ql-editor {
    min-height: 160px;
  }
`;

const ImageName = styled.div`
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  .spin-msg {
    position: absolute;
    top: 20px;
    bottom: 2px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    z-index: 1;
    span {
      position: relative;
      margin: auto;
      z-index: 11;
    }
  }
  .ant-upload-list-picture-card {
    .ant-upload-list-item {
      padding: 0;

      .ant-upload-list-item-info {
        .ant-upload-list-item-name {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          display: block !important;
          background-color: #e0e0e0;
          color: #555558;
          padding: 2px 3px;
          font-size: 10px;
        }
      }
    }
  }
`;

const FinishButtonWrapper = styled.div`
  padding: 8px;
  cursor: pointer;
  button {
    display: block;
    width: 100%;
  }
`;

const CommonTable = styled.div`
  table {
    td,
    th {
      white-space: nowrap;
      &.width-10 {
        width: 10%;
      }
      &.width-65 {
        width: 65%;
      }
      &.wrap {
        white-space: inherit;
      }
      &.horizontal-center {
        text-align: center;
      }
      &.horizontal-right {
        text-align: right;
      }
      &.smaller {
        font-size: 13px;
      }
    }
    td {
      &.less-important {
        color: ${palette('text', 3)};
        font-size: 13px;
      }
    }
    tr {
      cursor: pointer;
      .ant-tag {
        cursor: pointer;
      }
      td {
        .c-name {
          font-weight: 500;
          display: block;
        }
      }
    }
  }
  &.no-pointer {
    table tr {
      cursor: inherit;
      .ant-tag {
        cursor: inherit;
      }
    }
  }
  &.flex-full {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const Fieldset = styled.div`
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 30px;

  &.overflow-horizontal {
    overflow-x: auto;
  }
  &:last-child {
    margin-bottom: 10px;
  }
  &.smaller-margin {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 5px;
    }
  }
  // &.ant-select-dropdown--empty {
  //   &.ant-select-dropdown-menu-item-disabled {
  //     padding-right: 0px !important;
  //   }
  // }
  // .ant-input {
  //   &[disabled] {
  //     color: ${palette('text', 1)};
  //   }
  // }
  // textarea.ant-input {
  //   margin: 0;
  // }

  .validation-error,
  .validation-error .ant-select-selection,
  .validation-error .ant-calendar-picker-input {
    border-color: red;
  }
`;
const ActionWrapperStyle = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 auto;

  & > * {
    margin: auto;
  }

  .ant-btn {
    width: 24px;
    height: 24px;
    .anticon {
      font-size: 18px;
    }
    &.no-color {
      color: inherit;
    }
  }

  &.child-count-1 {
    width: 60px;
  }
  &.child-count-2 {
    width: 70px;
  }
  &.child-count-3 {
    width: 100px;
  }
  &.child-count-4 {
    width: 130px;
  }
  &.child-count-5 {
    width: 160px;
  }
`;

const InlineBlock = styled.div`
  display: inline-block;
`;

const Label = styled.label`
  position: relative;
  font-size: 13px;
  color: ${palette('text', 1)};
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  margin: 0 0 8px;

  &.small-margin {
    margin: 0 0 4px;
  }
  &.no-margin {
    margin: 0;
  }
`;

export const InlineFlexCentered = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

const SubLabelText = styled.span`
  color: ${palette('text', 5)};
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  display: inline-block;
  margin: 0 0 8px;

  &.no-margin {
    margin: 0;
  }
`;

export const SmallInfoText = styled.p`
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  margin: 0;

  &.gray {
    color: ${palette('text', 3)};
  }
  &.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const NormalTextXS = styled.p`
  font-size: 12px;
  &.no-margin {
    margin: 0;
  }
  &.title {
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 2px;
  }
`;

const NormalTextSmall = styled.p`
  font-size: 13px;
`;

const NormalTextLarge = styled.p`
  font-size: 15px;
`;

const MediumBoldText = styled.span`
  font-weight: 500;
`;

const ThinTextXXL = styled.span`
  color: ${palette('text', 1)};
  font-weight: 300;
  font-size: 17px;
`;

const ThinTextXL = styled.span`
  color: ${palette('text', 1)};
  font-weight: 300;
  font-size: 16px;
`;

const ThinTextL = styled.span`
  color: ${palette('text', 1)};
  font-weight: 300;
  font-size: 15px;
`;

const TableSubtitle = styled.div`
  margin-bottom: 20px;
  &.small-margin {
    margin-bottom: 5px;
  }
  &.no-margin {
    margin-bottom: 0;
  }
  span {
    font-size: 15px;
  }
`;
const ToggleSwitch = styled.div`
  float: right;
  margin: auto;
  margin-right: 0;
  &.save-space-right {
    margin-right: 6px;
  }
`;

const PopUpChange = styled.div`
  color: ${palette('custom', 7)};
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  p {
    margin-bottom: 0px;
  }
`;

const RoundImage = styled.div`
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  height: 80px;
  width: 80px;
  position: relative;
  &.preview {
    height: 104px;
    width: 104px;
    margin-right: 30px;
    .viewBtn {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s ease;
      background-color: rgba(0, 0, 0, 0.5);
      .anticon {
        color: #fff;
        font-size: 18px;
      }
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  &.smaller {
    height: 68px;
    width: 68px;
    margin-left: 6px;
  }
`;

const CardView = styled.div`
  width: 49%;
  display: inline-block;

  & > div {
    height: 100%;
    // For mandatesDetails
    .ant-table-wrapper {
      margin-bottom: 60px;
    }
  }
  p {
    margin-bottom: 0;
  }
  @media ${size('max_md')} {
    width: 100%;
    margin: 1% 0;
    width: 100%;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  @media ${size('max_md')} {
    display: inline-block;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
`;

const CardDataWithImage = styled.div`
  > div {
    display: inline-block;
    vertical-align: middle;

    @media ${size('max_lg')} {
      display: block;
      margin-left: 0px;
      h6 {
        margin-top: 18px;
      }
    }
    h6 {
      color: ${palette('text', 0)};
      margin-bottom: 0.5em;
    }
    p {
      margin-bottom: 2px;
      color: ${palette('custom', 4)};
      font-weight: 500;
    }
  }
`;

const CardDataWithoutImage = styled.div`
  margin-top: 30px;

  .ant-tag {
    margin-left: 8px;
  }
  h6 {
    color: ${palette('text', 0)};
    margin-bottom: 0.5em;
  }
  span,
  p {
    color: ${palette('text', 3)};
  }
  table,
  ul {
    color: ${palette('text', 3)};

    td:last-child {
      padding-left: 10px;
    }
  }
`;

const HtmlWrapper = styled.div`
  white-space: pre-line;
  cursor: text;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
`;

const DescriptionWrapper = styled.span`
  white-space: pre-line;
  cursor: text;

  span.anticon {
    margin-left: 4px;
    &.success-color {
      color: ${palette('status', 8)};
    }
    &.error-color {
      color: ${palette('status', 0)};
    }
  }
`;

const TimePickerWrapper = styled.div`
  .ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
    max-width: none;
  }
  .ant-time-picker-panel-select {
    width: 50%;
    ul {
      padding: 0;
      width: 100%;
    }
  }
  .ant-time-picker-panel-addon {
    text-align: center;
  }
`;

const LinkStyle = styled.a`
  &.danger {
    color: ${red.primary} !important;
    &:hover {
      background-color: ${red[0]} !important;
    }
  }
  &.hover-underline {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const CustomFormCollapse = styled(Collapse)`
  border: none;
  & > .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      background-color: white;
    }
    & > .ant-collapse-header {
      cursor: inherit !important;
      padding: 0 !important;
    }
    .ant-collapse-content {
      background-color: ${palette('grayscale', 5)};
    }
  }

  &.space-above-panel {
    .ant-collapse-content {
      margin-top: 8px;
    }
  }
`;

export const CustomFormCollapseWhite = styled(Collapse)`
  border: none;
  & > .ant-collapse-item {
    border: none;
    .ant-collapse-content-box {
      padding: 0;
    }
  }
`;

export const LinkButton = styled(Button)`
  padding: 0;
  width: auto;
  font-size: 11px;
  height: inherit;
  line-height: 1.25;
`;

export const LinkButtonXs = styled(Button)`
  padding: 0;
  width: auto;
  font-size: 12px;
  height: inherit;
  line-height: 1.25;
`;

export const PlusTag = styled(Tag)`
  border-style: dashed;
  background-color: #fff;
  cursor: pointer;

  .anticon {
    margin-right: 4px;
  }

  &:hover {
    color: ${blue.primary};
    border-color: ${blue.primary};
  }
`;

export const VoteChoicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;

  > input,
  > span {
    height: 28px;
    margin-bottom: 8px;
    line-height: 26px;
  }
  .ant-tag {
    padding: 0px 8px;
  }
`;

const AdminContainer = styled.div`
  width: 100%;
  .ant-badge {
    right: 9px;
    top: 12px;
    position: absolute;
    .ant-badge-dot {
      box-shadow: none;
    }
  }
  .ant-card.stats-card {
    .ant-card-head {
      font-size: 14px;
      font-weight: 400;
    }
    .ant-card-body {
      background: #fdfdfd;
      border-top: 1px solid #eee;
      /* We tried using same for immofonds - as structure is little different so create new one */
      .cardValueBlue {
        .ant-statistic-content {
          color: ${palette('custom', 18)};
        }
      }
      .cardValueCyan {
        .ant-statistic-content {
          color: ${palette('custom', 19)};
        }
      }
      .cardValueGreen {
        .ant-statistic-content {
          color: ${palette('custom', 20)};
        }
      }
    }
  }
`;

const AdminInlineOption = styled.div`
  margin: 4px 0;
  .ant-switch {
    margin-right: 10px;
  }
`;

export {
  ActionBtn,
  ActionWrapperStyle,
  AdminContainer,
  AdminInlineOption,
  ButtonHolders,
  ButtonWrapper,
  CardContainer,
  CardDataWithImage,
  CardDataWithoutImage,
  CardTitle,
  CardView,
  CommonTable,
  ComponentTitle,
  Container,
  CustomForm,
  CustomFormCollapse,
  DescriptionWrapper,
  DesktopMenu,
  ErrorMsg,
  Fieldset,
  FinishButtonWrapper,
  HtmlWrapper,
  ImageName,
  InlineBlock,
  Label,
  LinkStyle,
  MediumBoldText,
  NormalTextLarge,
  NormalTextSmall,
  NormalTextXS,
  PageHeaderExtraWrapper,
  PageSubTitle,
  PageTitle,
  PopUpChange,
  RoundImage,
  SearchBox,
  SmallStatusText,
  Spacer,
  StatusHandler,
  SubLabelText,
  TableSubtitle,
  TableWrapper,
  ThinTextL,
  ThinTextXL,
  ThinTextXXL,
  TimePickerWrapper,
  TinyStatusText,
  TitleAlert,
  TitleWrapper,
  ToggleSwitch,
  VerticalTabsSectionWrapper,
  WhiteIcon,
};
