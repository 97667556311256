import Icon, {
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Badge, Divider, Popover, Spin, Tooltip } from 'antd';
import { ReactComponent as ImmoAppIcon } from 'assets/images/app-icon.svg';
import { ReactComponent as ImmoFondsIcon } from 'assets/images/fonds-icon.svg';
import { ReactComponent as ImmoMoveIcon } from 'assets/images/move-icon.svg';
import toolSwitcher from 'assets/images/tool-switcher.svg';
import {
  LinkStyle,
  NormalTextLarge,
  SubLabelText,
} from 'assets/styles/globalStyledComponents';
import { IntlMessages } from 'components/Utility/IntlMessages';
import { getOptions } from 'containers/global/Sidebar/options';
import {
  ID,
  ProductStrings,
  STORAGE,
  USER_ROLE,
} from 'definitions/constants-fe';
import jwtDecode from 'jwt-decode';
import Firebase from 'library/firebase';
import {
  clearUserRole,
  getToken,
  getUserRole,
} from 'library/localStorageHelper';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authActions from 'redux/global/auth/actions';
import actions from 'redux/immoapp/actions';
import { PAGE, TOOL } from 'route.constants';
import { TopbarDropdownWrapper, UserContent } from './topbarDropdown.style';

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMenu: false,
      switcher: false,
      isMounted: false,
      pendingUserCount: 0,
      communicationDisabled: false,
    };
  }

  componentDidMount() {
    const { user_id } = jwtDecode(getToken());
    this.props.getUser({ uId: user_id });
    this.props.subscribeCommonSettings();
    this.props.subscribeUsers();
    this.props.getRemoteConfigAccessComments();
    this.loadLogo();
  }
  componentDidUpdate(prevProps) {
    if (this.props.rx_logoUpdateCounter !== prevProps.rx_logoUpdateCounter) {
      this.setState({ isMounted: false });
      this.loadLogo();
    }
    if (this.props.rx_commonSettings !== prevProps.rx_commonSettings) {
      this.setState({
        communicationDisabled:
          this.props.rx_commonSettings?.auto_send_emails !== true ||
          this.props.rx_commonSettings?.auto_send_push !== true,
      });
    }

    if (this.props.rx_userAccount !== prevProps.rx_userAccount) {
      if (
        this.props.rx_userAccount.role != null &&
        parseInt(getUserRole()) !== parseInt(this.props.rx_userAccount.role)
      ) {
        // We need to reload to resubscribe all realtime listeners
        // Since this happens almost never, this solution is perfectly fine
        clearUserRole();
        this.props.logout();
      }
      this.props.subscribeUsers();
    }

    if (this.props.rx_users !== prevProps.rx_users) {
      // Set pending users
      const pendingUser = this.props.rx_users.filter((u) => u.approved === 0);
      this.setState({ pendingUserCount: pendingUser.length });

      // Set my user data
      const { user_id } = jwtDecode(getToken());
      const myNewUserData = this.props.rx_users.find(
        (u) => u.key === user_id && u.approved === 1,
      );
      if (myNewUserData == null) {
        // This happens when localstorage contains token from other ENV --> Logout
        this.props.logout();
      } else {
        if (!_.isEqual(this.props.rx_userAccount, myNewUserData)) {
          this.props.setUser(myNewUserData);
        }
      }
    }
  }
  loadLogo = async () => {
    await Firebase.loadSingleFile(
      `${STORAGE.settings}/${STORAGE.client_logo}`,
    ).then((url) => {
      this.setState({ logo: url.url });
    });
  };
  renderLogo = (logo) => {
    return (
      <a
        href={`${process.env.REACT_APP_CLIENT_WEBSITE_URL}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={logo}
          onLoad={() => this.setState({ isMounted: true })}
          alt={`${process.env.REACT_APP_CLIENT_NAME_FULL} Logo`}
        />
      </a>
    );
  };
  switchTool = (tool, hasAccess) => {
    this.setState({ tool: tool, switcher: hasAccess ? false : true });
    if (hasAccess) {
      let baseUrl = null;
      switch (tool) {
        case ID.immoapp:
          baseUrl = `/${TOOL.IMMOAPP}/${PAGE.IA_DASHBOARD}`;
          break;
        case ID.immofonds:
          baseUrl = `/${TOOL.IMMOFONDS}/${PAGE.IF_HOME}`;
          break;
        case ID.immomove:
          baseUrl = `/${TOOL.IMMOMOVE}/${PAGE.IM_PROCESSES}`;
          break;
        default:
          break;
      }
      if (baseUrl) {
        this.props.history.push({ pathname: baseUrl });
      } else {
        return false;
      }
    }
  };

  render() {
    const url = this.props.history.location.pathname.split('/');
    const { rx_userAccount, switcherIconBorderClass } = this.props;
    const immoAppAccess = process.env.REACT_APP_IA === 'true';
    const immoFondsAccess =
      process.env.REACT_APP_IF === 'true' &&
      rx_userAccount?.role >= USER_ROLE.manager.value;
    const immoMoveAccess =
      process.env.REACT_APP_IM === 'true' &&
      rx_userAccount?.role >= USER_ROLE.manager.value;
    const multipleToolsActive =
      [immoAppAccess, immoFondsAccess, immoMoveAccess].reduce(
        (a, b) => a + (b === true ? 1 : 0),
        0,
      ) >= 2;

    const {
      isMounted,
      logo,
      pendingUserCount,
      communicationDisabled,
      switcher,
      userMenu,
    } = this.state;

    let userInitials = '..';
    if (rx_userAccount.first_name != null && rx_userAccount.last_name != null) {
      const letterOne = rx_userAccount.first_name
        .replace(/[^a-zA-Z]/g, '')
        .substring(0, 1)
        .toUpperCase();
      const letterTwo = rx_userAccount.last_name
        .replace(/[^a-zA-Z]/g, '')
        .substring(0, 1)
        .toUpperCase();
      userInitials = letterOne + letterTwo;
    }
    const userContent = (
      <UserContent>
        <ul>
          {/* Account */}
          {rx_userAccount.role >= USER_ROLE.tenant_normal.value && (
            <>
              <li style={{ cursor: 'auto' }}>
                <LinkStyle style={{ cursor: 'auto', background: '#fff' }}>
                  <div className="user-initials">
                    <span>{userInitials}</span>
                  </div>
                  <div className="user-details">
                    <NormalTextLarge>
                      {`${rx_userAccount.first_name} ${rx_userAccount.last_name}`}
                    </NormalTextLarge>
                    <SubLabelText className="no-margin">
                      {`${rx_userAccount.email}`}
                    </SubLabelText>
                  </div>
                </LinkStyle>
              </li>
              <Divider className="small-margin" />
            </>
          )}
          {/* Mobile */}
          {window.innerWidth < 768 && url.includes(ID.immoapp) && (
            <>
              {getOptions().map(
                (ele) =>
                  rx_userAccount.role >= ele.minRole && (
                    <li key={ele.key}>
                      <LinkStyle
                        onClick={() => {
                          this.setState({ userMenu: false });
                          this.props.history.push({
                            pathname: `/${ele.key}`,
                          });
                        }}
                      >
                        <Icon component={ele.icon} />
                        <IntlMessages id={ele.label} />
                      </LinkStyle>
                    </li>
                  ),
              )}
              <Divider className="small-margin" />
            </>
          )}
          {/* Staff */}
          {rx_userAccount.role >= USER_ROLE.manager.value && (
            <>
              <li>
                <LinkStyle
                  onClick={() => {
                    this.setState({ userMenu: false });
                    this.props.history.push({
                      pathname: `/${TOOL.STATISTICS}`,
                    });
                  }}
                >
                  <PieChartOutlined />
                  <IntlMessages id="global.statistics" />
                </LinkStyle>
              </li>
              <li>
                <LinkStyle
                  onClick={() => {
                    this.setState({ userMenu: false });
                    this.props.history.push({
                      pathname: `/${TOOL.SETTINGS}`,
                    });
                  }}
                >
                  <SettingOutlined />
                  <IntlMessages id="menu.managePlatform" />
                  {(pendingUserCount > 0 || communicationDisabled) && (
                    <Badge
                      className={
                        communicationDisabled
                          ? ID.communicationDisabledBadge
                          : ID.pendingUserBadge
                      }
                      dot
                    />
                  )}
                </LinkStyle>
              </li>
              <Divider className="small-margin" />
            </>
          )}
          <li>
            <LinkStyle onClick={() => this.props.logout()} className="danger">
              <LogoutOutlined />
              <IntlMessages id="global.logout" />
            </LinkStyle>
          </li>
        </ul>
      </UserContent>
    );

    const switcherContent = (
      <TopbarDropdownWrapper className="isoUserDropdown icon-box">
        <ul>
          <Tooltip
            placement="bottom"
            title={
              !immoAppAccess ? <IntlMessages id="global.noAccessForTool" /> : ''
            }
          >
            <li
              onClick={this.switchTool.bind(this, ID.immoapp, immoAppAccess)}
              className={url.includes(ID.immoapp) ? 'active' : ''}
            >
              <ImmoAppIcon className={immoAppAccess ? 'icon-app' : ''} />
              <h4>{ProductStrings.immoapp}</h4>
            </li>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={
              !immoFondsAccess ? (
                <IntlMessages id="global.noAccessForTool" />
              ) : (
                ''
              )
            }
          >
            <li
              onClick={this.switchTool.bind(
                this,
                ID.immofonds,
                immoFondsAccess,
              )}
              className={url.includes(ID.immofonds) ? 'active' : ''}
            >
              <ImmoFondsIcon className={immoFondsAccess ? 'icon-fonds' : ''} />
              <h4>{ProductStrings.immofonds}</h4>
            </li>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={
              !immoMoveAccess ? (
                <IntlMessages id="global.noAccessForTool" />
              ) : (
                ''
              )
            }
          >
            <li
              onClick={this.switchTool.bind(this, ID.immomove, immoMoveAccess)}
              className={url.includes(ID.immomove) ? 'active' : ''}
            >
              <ImmoMoveIcon className={immoMoveAccess ? 'icon-move' : ''} />
              <h4>{ProductStrings.immomove}</h4>
            </li>
          </Tooltip>
        </ul>
      </TopbarDropdownWrapper>
    );

    return (
      <div className="icon-section">
        {rx_userAccount.role >= USER_ROLE.janitor.value && multipleToolsActive && (
          <Popover
            content={switcherContent}
            trigger="click"
            open={switcher}
            onOpenChange={() => this.setState({ switcher: !switcher })}
            placement="bottomRight"
          >
            <li className={`isoUser main-icon ${switcherIconBorderClass}`}>
              <div className="isoImgWrapper">
                <img src={toolSwitcher} alt="tool switch"></img>
              </div>
            </li>
          </Popover>
        )}
        <Popover
          content={userContent}
          trigger="click"
          open={userMenu}
          onOpenChange={() => this.setState({ userMenu: !userMenu })}
          placement="bottomRight"
          getPopupContainer={(trigger) => trigger.parentNode}
          overlayClassName="userContent"
        >
          <li className="isoUser">
            <div className={`isoImgWrapper ${userMenu ? 'menuOpen' : ''}`}>
              <div id="hamburger">
                <span />
                <span />
                <span />
              </div>
              <span className="userActivity online" />
            </div>
            {rx_userAccount.role >= USER_ROLE.manager.value &&
              (pendingUserCount > 0 || communicationDisabled) && (
                <Badge
                  className={
                    communicationDisabled
                      ? ID.communicationDisabledBadge
                      : ID.pendingUserBadge
                  }
                  dot
                />
              )}
          </li>
        </Popover>
        <li
          className={`isoClientLogo ${
            rx_userAccount.role >= USER_ROLE.manager.value ? 'hide' : 'show'
          }`}
        >
          <Spin spinning={!isMounted}>
            <div className="img-section">
              {logo ? this.renderLogo(logo) : <span />}
            </div>
          </Spin>
        </li>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    ...state.ImmoApp, // Todo this is only for users and should be global
    rx_userAccount: state.GL_Auth.rx_userAccount,
  }),
  { ...actions, ...authActions },
)(withRouter(TopbarUser));
